import React, { Component } from "react";
import { Link } from "react-scroll";
import ExpMiniCat from "./components/ExpMiniCat";
// import CertMiniCat from "./components/CertMiniCat";
import SkillsMiniCat from "./components/SkillsMiniCat";
// import StackMiniCat from "./components/StackMiniCat";

export default class MiniCategories extends Component {
  resumeInfoTxts = {
    experience: [
      {
        key: 0,
        fromM: "10",
        fromY: "2023",
        toM: "9",
        toY: "2024",
        title: "Engineering Lead",
        place: "Owners",
        website: "https://joinowners.com",
        iconCss: "flaticon-flasks",
      },
      {
        key: 0,
        fromM: "10",
        fromY: "2022",
        toM: "9",
        toY: "2023",
        title: "Sr Full Stack Engineer",
        place: "Owners",
        website: "https://joinowners.com",
        iconCss: "flaticon-flasks",
      },
      {
        key: 0,
        fromM: "3",
        fromY: "2022",
        toM: "10",
        toY: "2022",
        title: "Sr Front End Engineer",
        place: "top.legal",
        website: "https://top.legal",
        iconCss: "flaticon-web-design",
        description: "Better contracts, simpler workflows. More turnover.Development and migration of an innovative platform to finalize deals and contracts, generation of networks for contracting parties and creator of templates for contracts. Frontend architecture management, focused on components through Storybook with Figma and connection with App-sync and AWS services, considering backward compatibility with legacy app.",
      },
      {
        key: 0,
        fromM: "3",
        fromY: "2021",
        toM: "3",
        toY: "2022",
        title: "Sr Full Stack Engineer",
        place: "Kitspace",
        website: "https://kitspace.com",
        iconCss: "flaticon-flasks",
        description: "Development and architecture from scratch of React applications and Nest.js APIs oriented to the management of projects and tasks related to real estate and construction developments. Interface management for the connections of multiple isolated applications through Firebase, integrations with serverless services (Vercel & Digital Ocean), automation for reporting tasks related to Puppetter (Headless Chrome) and SendGrid, Authorization controls for private services outside of closed networks.",
      },
      {
        key: 0,
        fromM: "8",
        fromY: "2020",
        toM: "3",
        toY: "2021",
        title: "Sr Full Stack Engineer",
        place: "Neoris",
        website: "https://neoris.com",
        iconCss: "flaticon-flasks",
        description: "Development of React web applications for a catalog of tools for the largest communications medium in Argentina. With a model of multiple uses to be able to reuse the systems in the media and journalism in other countries. Bundle-split and CI for +50 web apps running in a long ecosystem with more than 300 devs",
      },
      {
        key: 0,
        fromM: "5",
        fromY: "2020",
        toM: "10",
        toY: "2020",
        title: "Full Stack Engineer",
        place: "Sortd",
        website: "https://sortd.io",
        iconCss: "flaticon-flasks",
        description: "Sortd is a collaborative, online, organisational change management platform which helps companies get the most out of their change programmes. Develop a refactor based on UmiJS and Ant Design, the application consists of business tools for organizations. We use Firebase for the most important backend functions and realtime features to improve the user interface.",
      },
      {
        key: 0,
        fromM: "1",
        fromY: "2017",
        toM: "5",
        toY: "2020",
        title: "Full Stack Developer",
        place: "eTips INC",
        website: "https://etips.com",
        iconCss: "flaticon-flasks",
        description:
          "Web tools & SPA for automation and building +1500 Android/IOS apps for all the main tourist destination in the world. Including apps for governments. Development Web Views/AMP and APIs for ecommerce and payment (Stripe) related with travel environment. Integration and management of small teams (3-7 persons) of frontend developers and android developers. \n \n ▲ Laravel ▲ PHP ▲ JavaScript ▲ Reactjs ▲ Node.js ▲ Angularjs ▲ Express ▲ Apache ▲ Yii2 ▲ Fastlane ▲ AWS ▲ MySQL ▲ Ubuntu ▲ Bash ▲ Angular Material",
      },
      {
        key: 1,
        fromM: "6",
        fromY: "2016",
        toM: "1",
        toY: "2017",
        title: "Front End Developer",
        place: "eTips INC",
        website: "https://etips.com",
        iconCss: "flaticon-web-design",
        description:
          "Frontend Tools Focused on delivery content and package manager for travel apps, offering maps, tours, AR, in-app-purchase connecting with iTunes Connect (Apple Inc.)",
      },
      {
        key: 2,
        fromM: "10",
        fromY: "2017",
        toM: "",
        toY: "Current",
        title: "Founder",
        place: "Panda Goals",
        website: "https://pandagoals.com",
        iconCss: "flaticon-innovation",
        description:
          "Panda Goals is a tool for improve workflow. Get the best performance with modern organization methodologies [+ 500 users/clients] \n \n More: pandagoals.com & shop-insta.com \n \n ▲ Laravel ▲ PHP ▲ Angularjs ▲ MongoDB ▲ MySQL ▲ Reactjs ▲ Redux",
      },
      {
        key: 3,
        fromM: "1",
        fromY: "2016",
        toM: "6",
        toY: "2016",
        title: "Software Developer / Analytics",
        place: "Invap S.E",
        website: "https://invap.com.ar",
        iconCss: "flaticon-analysis",
        description: "",
      },
      {
        key: 4,
        fromM: "7",
        fromY: "2015",
        toM: "12",
        toY: "2015",
        title: "Information Systems Intern",
        place: "Invap S.E",
        website: "https://invap.com.ar",
        iconCss: "flaticon-analysis",
        description: "",
      },
    ],
    certifications: {},
  };

  render() {
    return (
      <section
        className="ftco-section ftco-no-pb goto-here"
        id="resume-section"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <nav id="navi">
                <span>Shortcuts ⬇</span>
                <ul>
                  <li>
                    <Link
                      activeClass="current"
                      offset={-120}
                      spy={true}
                      smooth={true}
                      to="page-2"
                    >
                      Experience
                    </Link>
                  </li>
                  {/* <li>
                    <Link activeClass="current" offset={-120} spy={true} smooth={true} to="page-1">
                      Certifications
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      activeClass="current"
                      offset={-120}
                      spy={true}
                      smooth={true}
                      to="page-3"
                    >
                      Skills
                    </Link>
                  </li>
                  {/* <li>
                    <Link activeClass="current" offset={-120} spy={true} smooth={true} to="page-4">
                      Awards
                    </Link>
                  </li> */}
                </ul>
              </nav>
            </div>
            <div className="col-md-9">
              <div id="page-2" className="page two">
                <ExpMiniCat experiences={this.resumeInfoTxts.experience} />
              </div>
              {/* <div id="page-1" className="page one">
                <CertMiniCat />
              </div> */}
              <div id="page-3" className="page three">
                <SkillsMiniCat />
              </div>
              {/* <div id="page-4" className="page four">
                <StackMiniCat />
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
